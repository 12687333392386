import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { db, auth } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { trackEvent, trackError } from "../utils/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { handleTikTokWebview } from "../utils/webviewHelper";
import { sendEmailVerification } from "firebase/auth";

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const pricingOptions = [
  {
    id: 2,
    title: "Premium Membership",
    subtitle: "£12/month",
    originalPrice: "£30",
    savings: "60%",
    savingsAmount: "£18",
    tag: "7-Day Free Trial - Best Value",
    description: [
      "Includes unlimited tokens per month for AI-generated content ideas",
      "Full access to all features and exclusive templates",
      "Dedicated support with fast response times",
      "Save £18/month with our introductory offer!",
      "Cancel anytime",
    ],
    credits: -1,
    priceId: process.env.REACT_APP_PREMIUM_TIER_PRICE_ID,
  },
  {
    id: 1,
    title: "Standard Membership",
    subtitle: "£8/month",
    originalPrice: "£15",
    savings: "47%",
    savingsAmount: "£7",
    tag: "7-Day Free Trial",
    description: [
      "Includes 30 credits per month for AI-generated content ideas",
      "Access to all features and premium templates",
      "Priority support and regular updates",
      "Save £7/month with our introductory offer!",
      "Cancel anytime",
    ],
    credits: 30,
    priceId: process.env.REACT_APP_STANDARD_TIER_PRICE_ID,
  },
];

function Pricing() {
  const { currentUser, error: authError, setError: setAuthError } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [localError, setLocalError] = useState("");
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";

  // Clear auth error when component unmounts
  useEffect(() => {
    return () => {
      setAuthError(null);
    };
  }, [setAuthError]);

  // Function to fetch user credits
  const fetchUserCredits = async (uid) => {
    try {
      const userRef = doc(db, "users", uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        return userData.credits || 0;
      }
      return 0;
    } catch (error) {
      console.error("Error fetching user credits:", error);
      return 0;
    }
  };

  // Check credits when user changes
  useEffect(() => {
    if (currentUser) {
      fetchUserCredits(currentUser.uid);
    }
  }, [currentUser]);

  const updateUserSubscriptionStatus = useCallback(async () => {
    if (!currentUser) return;

    const userRef = doc(db, "users", currentUser.uid);

    try {
      const queryParams = new URLSearchParams(location.search);
      const priceId = queryParams.get("priceId");
      const selectedPlan = pricingOptions.find(
        (option) => option.priceId === priceId
      );

      // Calculate trial end date (7 days from now)
      const trialEnd = new Date();
      trialEnd.setDate(trialEnd.getDate() + 7);

      const updateData = {
        hasSubscription: true,
        isInTrial: true,
        trialEndsAt: trialEnd,
        credits: selectedPlan ? selectedPlan.credits : 100,
        lastUpdated: new Date(),
        subscriptionPlan: priceId,
        subscriptionStatus: "trialing",
      };

      await updateDoc(userRef, updateData);
      trackEvent("subscription_update", {
        plan: selectedPlan?.title || "unknown",
        credits: selectedPlan?.credits || 100,
        trial: true,
      });
      console.log("User subscription status updated successfully");
    } catch (error) {
      console.error("Error updating user subscription status:", error);
      trackError("subscription_update_failed", error.message);
    }
  }, [currentUser, location.search]);

  // Define handleCheckout first
  const handleCheckout = useCallback(
    async (priceId) => {
      setIsLoading(true);
      setLoadingMessage("Preparing checkout...");

      try {
        // First ensure customer exists using callable function
        const createOrGetCustomer = httpsCallable(
          getFunctions(),
          "createOrGetCustomer"
        );
        await createOrGetCustomer({
          email: currentUser.email,
          name: currentUser.displayName || currentUser.email,
        });

        // Create checkout session using callable function
        const createCheckoutSession = httpsCallable(
          getFunctions(),
          "createCheckoutSession"
        );
        const { data } = await createCheckoutSession({
          priceId,
          successUrl: window.location.href.split("#")[0] + "#/generate",
          cancelUrl: window.location.href.split("#")[0] + "#/pricing",
        });

        if (!data.sessionId) {
          throw new Error("No session ID returned");
        }

        // Load Stripe and redirect immediately
        const stripe = await stripePromise;
        if (!stripe) {
          throw new Error("Payment system not ready");
        }

        const result = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (err) {
        console.error("Stripe operation failed:", err);
        setLocalError(
          err.message || "Failed to setup payment. Please try again."
        );
        setIsLoading(false);
        setLoadingMessage("");
      }
    },
    [currentUser]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get("success");
    const canceled = queryParams.get("canceled");
    const priceId = queryParams.get("priceId");
    const shouldCheckout = queryParams.get("checkout") === "true";

    if (success === "true" || canceled === "true") {
      trackEvent("checkout_result", {
        result: success === "true" ? "success" : "canceled",
      });

      if (success === "true" && priceId) {
        updateUserSubscriptionStatus();
      }
    }

    // Auto-start checkout if coming from registration
    if (shouldCheckout && priceId && currentUser) {
      setIsLoading(true);
      setLoadingMessage("Preparing your checkout...");
      handleCheckout(priceId).catch((err) => {
        console.error("Error in auto-checkout:", err);
        setLocalError(err.message || "Failed to start checkout");
        setIsLoading(false);
        setLoadingMessage("");
      });
    }
  }, [
    location.search,
    updateUserSubscriptionStatus,
    currentUser,
    handleCheckout,
  ]);

  // After successful authentication, proceed with checkout
  useEffect(() => {
    if (currentUser && selectedPriceId) {
      handleCheckout(selectedPriceId);
      setSelectedPriceId(null);
    }
  }, [currentUser, selectedPriceId, handleCheckout]);

  const handleGetStarted = async (priceId) => {
    try {
      const selectedPlan = pricingOptions.find(
        (option) => option.priceId === priceId
      );

      // Check for TikTok webview before proceeding with anything
      if (handleTikTokWebview(setLocalError)) {
        return;
      }

      setLocalError("");
      setAuthError(null);

      // If not logged in, add transition before navigating
      if (!currentUser) {
        trackEvent("auth_required", {
          trigger: "subscription_attempt",
          plan: selectedPlan?.title || "unknown",
        });
        setSelectedPriceId(priceId);

        // Add fade transition
        document.body.style.opacity = "0";
        document.body.style.transition = "opacity 0.5s ease";

        setTimeout(() => {
          navigate(`/login?mode=register&priceId=${priceId}`);
          // Reset opacity after navigation
          document.body.style.opacity = "1";
        }, 500);
        return;
      }

      await handleCheckout(priceId);
    } catch (err) {
      console.error("Error:", err);
      setLocalError(err.message || "An error occurred. Please try again.");
      setIsLoading(false);
      setLoadingMessage("");
    }
  };

  // Move the verification check useEffect here, before any conditional returns
  useEffect(() => {
    let interval;
    if (currentUser && !currentUser.emailVerified) {
      interval = setInterval(async () => {
        await currentUser.reload();
        if (currentUser.emailVerified) {
          // Update Firestore when email is verified
          const userRef = doc(db, "users", currentUser.uid);
          await updateDoc(userRef, {
            emailVerified: true,
            lastUpdated: new Date(),
          });
          clearInterval(interval);
        }
      }, 5000); // Check every 5 seconds
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentUser]);

  // Also update the manual check button to update Firestore
  const handleVerificationCheck = async () => {
    setIsLoading(true);
    try {
      await currentUser.reload();
      const user = auth.currentUser; // Get fresh user data
      if (user.emailVerified) {
        const userRef = doc(db, "users", user.uid);

        // Get the user data to check for selectedPriceId
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        await updateDoc(userRef, {
          emailVerified: true,
          lastUpdated: new Date(),
        });

        // Check URL parameters for priceId and checkout
        const queryParams = new URLSearchParams(location.search);
        const priceId = queryParams.get("priceId");

        // Check all possible sources for priceId
        const finalPriceId =
          priceId || selectedPriceId || userData?.selectedPriceId;

        if (finalPriceId) {
          // Clear the selectedPriceId from Firestore
          await updateDoc(userRef, {
            selectedPriceId: null,
          });
          setSelectedPriceId(null);
          await handleCheckout(finalPriceId);
        } else {
          window.location.reload();
        }
      } else {
        setLocalError(
          "Email not verified yet. Please check your email and click the verification link."
        );
      }
    } catch (error) {
      console.error("Error checking verification:", error);
      setLocalError("Failed to check verification status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Add verification screen if user is logged in but not verified
  if (currentUser && !currentUser.emailVerified) {
    return (
      <Container className="d-flex align-items-start justify-content-center min-vh-100">
        <div className="w-100 mt-5" style={{ maxWidth: "400px" }}>
          <Card className="shadow" style={{ background: "white" }}>
            <Card.Body className="text-center p-4">
              <h2 className="mb-4">Verify Your Email</h2>
              <p className="text-muted mb-4">
                Please check your email and verify your account to continue.
              </p>
              {localError && <Alert variant="danger">{localError}</Alert>}
              {isLoading && (
                <div className="text-center mb-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">{loadingMessage}</span>
                  </div>
                </div>
              )}
              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  onClick={() => {
                    sendEmailVerification(currentUser);
                    setLocalError(
                      "Verification email sent! Please check your inbox."
                    );
                  }}
                  className="mb-3"
                  disabled={isLoading}
                >
                  Resend Verification Email
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={handleVerificationCheck}
                  disabled={isLoading}
                >
                  I've Already Verified
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    );
  }

  return (
    <Container
      className={`min-vh-100 d-flex align-items-center flex-column justify-content-center position-relative ${
        !isHomePage ? "pricing-standalone" : ""
      }`}
      style={{ opacity: 0, animation: "fadeIn 0.3s ease-in forwards" }}
    >
      <style>
        {`
          ${
            !currentUser
              ? `
            .pricing-standalone {
              padding-top: 80px !important;
            }
            @media (min-width: 992px) {
              .pricing-standalone {
                margin-top: 120px !important;
                padding-top: 60px !important;
              }
            }
          `
              : `
            .pricing-standalone {
              padding-top: 0 !important;
            }
            @media (min-width: 992px) {
              .pricing-standalone {
                margin-top: 0 !important;
                padding-top: 0 !important;
              }
            }
          `
          }
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
      {isLoading && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: "rgba(0,0,0,0.85)",
            zIndex: 1000,
            backdropFilter: "blur(5px)",
          }}
        >
          <div className="text-center text-white">
            <div className="spinner-wrapper mb-3">
              <div className="spinner-ring"></div>
              <style>
                {`
                  .spinner-wrapper {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    margin: 0 auto;
                  }
                  .spinner-ring {
                    width: 100%;
                    height: 100%;
                    border: 2px solid #ffffff;
                    border-top-color: transparent;
                    border-radius: 50%;
                    animation: spinnerOne 0.6s linear infinite;
                  }
                  @keyframes spinnerOne {
                    to {
                      transform: rotate(360deg);
                    }
                  }
                `}
              </style>
            </div>
            <h4
              className="mb-0"
              style={{ fontSize: "1.1rem", fontWeight: "500" }}
            >
              {loadingMessage}
            </h4>
          </div>
        </div>
      )}
      {isHomePage && (
        <Row>
          <h1 className="text-center my-5">Our Pricing</h1>
        </Row>
      )}
      {authError && (
        <div
          className="alert alert-warning text-center mx-auto mb-4"
          style={{
            maxWidth: "600px",
            background: "#fff3cd",
            border: "1px solid #ffeeba",
          }}
        >
          {authError === "Please open in your default browser to continue" ? (
            <div>
              <h5 className="mb-3">Open in Browser</h5>
              <p className="mb-3">
                To continue with sign in, please open this page in your default
                browser.
              </p>
              <Button
                onClick={() => window.open(window.location.href, "_blank")}
                variant="warning"
                className="mt-2"
              >
                Open in Browser
              </Button>
            </div>
          ) : (
            <p>{authError}</p>
          )}
        </div>
      )}
      {localError && (
        <div
          className="alert alert-info text-center mx-auto mb-4"
          style={{
            maxWidth: "600px",
            background: "#f8f9fa",
            border: "1px solid #dee2e6",
          }}
        >
          {localError === "Please open in your default browser to continue" ? (
            <div>
              <h5 className="mb-3">Open in Browser</h5>
              <p className="mb-3">
                To continue with sign in, please open this page in your default
                browser.
              </p>
              <Button
                onClick={() => window.open(window.location.href, "_blank")}
                variant="primary"
                className="mt-2"
              >
                Open in Browser
              </Button>
            </div>
          ) : typeof localError === "string" ? (
            <p className="text-danger">{localError}</p>
          ) : (
            localError
          )}
        </div>
      )}
      <Row>
        {pricingOptions.map((option) => (
          <Col md={4} key={option.id} className="mb-4">
            <Card
              className={
                option.id === 3 ? "edu-card special-color" : "edu-card"
              }
            >
              <Card.Body className="edu-card-body">
                {option.tag && (
                  <div
                    className="pricing-tag"
                    style={{
                      background:
                        option.id === 3
                          ? "linear-gradient(45deg, #FF416C, #FF4B2B)"
                          : "#4b0082",
                    }}
                  >
                    {option.tag}
                  </div>
                )}
                <Card.Title className="edu-card-title">
                  {option.title}
                </Card.Title>
                <Card.Subtitle className="edu-card-subtitle mb-2">
                  {option.subtitle}
                  {option.originalPrice && (
                    <div className="pricing-details">
                      <span
                        className="original-price text-decoration-line-through ms-2"
                        style={{
                          fontSize: "0.5em",
                          opacity: "0.8",
                          position: "relative",
                          top: "-8px",
                          padding: "8px ",
                        }}
                      >
                        was {option.originalPrice}/month
                      </span>
                      <div
                        className="savings-badge"
                        style={{
                          background: option.id === 3 ? "#FF416C" : "#4b0082",
                          color: "white",
                          padding: "3px 10px",
                          borderRadius: "12px",
                          fontSize: "0.8em",
                          display: "inline-block",
                          marginLeft: "8px",
                          fontWeight: "600",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                      >
                        Save {option.savings}
                      </div>
                    </div>
                  )}
                </Card.Subtitle>
                <div className="edu-card-text">
                  <ul>
                    {option.description.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <Button
                  className="custom-btn"
                  onClick={() => handleGetStarted(option.priceId)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-content-center">
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                          fill="none"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Processing...
                    </div>
                  ) : option.id === 1 ? (
                    "Start Free Trial"
                  ) : (
                    "Start 7-Day Free Trial"
                  )}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Pricing;
