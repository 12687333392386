import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  ListGroup,
  Alert,
  Spinner,
  Toast,
  Accordion,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  increment,
} from "firebase/firestore";
import {
  FaPersonBooth,
  FaStore,
  FaMagic,
  FaTrash,
  FaSave,
  FaEye,
} from "react-icons/fa";
import { generateContent } from "../utils/openaiClient";
import DOMPurify from "dompurify";
import { trackEvent, trackFeatureUse, trackError } from "../utils/analytics";

const PROMPT_TEMPLATE = {
  system:
    "You are an idea generator for TikTok beginners. Focus on generating unique, simple video concepts that anyone can do. Each idea should be different from the last, but always achievable with just a phone. Emphasize strong hooks and clear, actionable concepts.",
  template: `<h2>🎯 Core Concept</h2>
<p><strong>Title:</strong> <span class="video-title">[30 chars - something simple but catchy]</span></p>
<p><strong>Target Audience:</strong> [who this video is perfect for]</p>
<p><strong>Main Problem Solved:</strong> [specific pain point or need this addresses]</p>
<p><strong>Unique Angle:</strong> [what makes this different from similar videos]</p>

<h2>🪝 Hook Strategy</h2>
<p><strong>Opening Line:</strong> [5-7 words that grab attention instantly]</p>
<p><strong>Visual Hook:</strong> [what people see in first 1-2 seconds]</p>
<p><strong>Pattern Interrupt:</strong> [unexpected element that stops scrolling]</p>
<p><strong>Promise/Payoff:</strong> [clear value viewer will get from watching]</p>

<h2>📝 Content Summary</h2>
<p><strong>Key Message:</strong> [one sentence - core takeaway]</p>
<p><strong>Supporting Points:</strong></p>
<ul>
<li>[first key point that builds credibility]</li>
<li>[second point that adds value]</li>
<li>[final point that drives action]</li>
</ul>
<p><strong>Call to Action:</strong> [specific next step for viewers]</p>

<h2>📜 Script</h2>
<div class="script-section">
<p><strong>Hook (0-3s):</strong><br/>[Opening line with exact words to say]</p>
<p><strong>Setup (3-15s):</strong><br/>[Detailed script for introducing the problem/solution]</p>
<p><strong>Main Content (15-30s):</strong><br/>[Step-by-step script with key points]</p>
<p><strong>Close (30-45s):</strong><br/>[Closing script with call to action]</p>
</div>

<h2>🎥 Recording Plan</h2>
<ol>
<li><strong>Hook (0-3s):</strong> [detailed hook execution]</li>
<li><strong>Setup (3-15s):</strong> [how to present the problem/solution]</li>
<li><strong>Content (15-30s):</strong> [step-by-step delivery]</li>
<li><strong>Close (30-45s):</strong> [ending that encourages engagement]</li>
</ol>

<h2>🏷️ Strategic Tags</h2>
<ul>
<li><strong>#[niche]</strong> [why this fits your content]</li>
<li><strong>#[trend]</strong> [how to leverage current trends]</li>
<li><strong>#[growth]</strong> [discovery tag strategy]</li>
</ul>

<h2>💡 Production Tips</h2>
<ul>
<li><strong>Camera Setup:</strong> [specific phone placement and angles]</li>
<li><strong>Lighting:</strong> [practical lighting solutions]</li>
<li><strong>Audio:</strong> [sound choice and recording tips]</li>
<li><strong>Transitions:</strong> [simple but effective transitions]</li>
</ul>

<h2>✨ Engagement Boosters</h2>
<ul>
<li><strong>Question Prompt:</strong> [question to ask viewers]</li>
<li><strong>Controversy Hook:</strong> [debatable point to discuss]</li>
<li><strong>Follow-up Content:</strong> [idea for part 2]</li>
</ul>`,
};

const useProgressAnimation = (isLoading, setProgress, setLoadingStage) => {
  const progressRef = React.useRef();

  React.useEffect(() => {
    if (isLoading) {
      setProgress(0);
      setLoadingStage("Starting...");

      // Quick initial progress
      progressRef.current = setTimeout(() => {
        setProgress(40);
        setLoadingStage("Generating your content...");
      }, 300);
    }

    return () => {
      if (progressRef.current) {
        clearTimeout(progressRef.current);
      }
    };
  }, [isLoading, setProgress, setLoadingStage]);

  return progressRef;
};

function GenerateIdeas() {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [generatedContent, setGeneratedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [credits, setCredits] = useState(0);
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const db = getFirestore();
  const [generationType, setGenerationType] = useState("personal");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [savedIdeaId, setSavedIdeaId] = useState(null);
  const navigate = useNavigate();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [loadingStage, setLoadingStage] = useState("");

  // Memoize the parsed content
  const parsedContent = useMemo(() => {
    if (!generatedContent) return null;

    const parser = new DOMParser();
    const doc = parser.parseFromString(generatedContent, "text/html");
    const sections = doc.querySelectorAll("h2");

    const scriptSection = Array.from(sections).find((section) =>
      section.textContent.includes("Script")
    );
    const otherSections = Array.from(sections).filter(
      (section) => !section.textContent.includes("Script")
    );

    return [...otherSections, scriptSection].filter(Boolean);
  }, [generatedContent]);

  const renderGeneratedContent = () => {
    if (!generatedContent || !parsedContent) return null;

    return (
      <Accordion defaultActiveKey="0">
        {parsedContent.map((section, index) => {
          const title = section.textContent;
          let content = "";
          let nextElement = section.nextElementSibling;

          const contentElements = [];
          while (nextElement && nextElement.tagName !== "H2") {
            contentElements.push(nextElement.outerHTML);
            nextElement = nextElement.nextElementSibling;
          }
          content = contentElements.join("");

          const isScriptSection = title.includes("Script");
          const headerStyle = isScriptSection
            ? {
                background: "linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)",
                color: "white",
                borderRadius: "8px",
                margin: "0",
              }
            : {};

          return (
            <Accordion.Item
              eventKey={index.toString()}
              key={index}
              className={isScriptSection ? "script-section" : ""}
            >
              <Accordion.Header
                style={headerStyle}
                className={isScriptSection ? "script-header" : ""}
              >
                {title}
              </Accordion.Header>
              <Accordion.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    );
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } else if (!currentUser.hasSubscription) {
      navigate("/generate");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            // Check if free credits have expired
            if (userData.freeCreditsExpiration && !userData.hasSubscription) {
              const now = new Date();
              const expirationDate = userData.freeCreditsExpiration.toDate();
              if (now > expirationDate) {
                // Free credits have expired, set credits to 0
                await updateDoc(userRef, { credits: 0 });
                setCredits(0);
              } else {
                setCredits(userData.credits || 0);
              }
            } else {
              setCredits(userData.credits || 0);
            }
          }
        } catch (error) {
          console.error("Error fetching user credits:", error);
          setError("Failed to load credits. Please try again.");
        }
      }
    };

    fetchUserCredits();
  }, [currentUser, db]);

  const addTag = (e) => {
    e.preventDefault(); // Prevent form submission
    if (newTag.trim() !== "") {
      setTags([...tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addTag(e);
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  useProgressAnimation(isLoading, setGenerationProgress, setLoadingStage);

  const handleGenerate = async () => {
    // Check if user has unlimited credits or -1 credits
    if (!currentUser?.hasUnlimitedCredits && credits !== -1 && credits <= 0) {
      setShowSubscriptionModal(true);
      trackEvent("subscription_prompt", { trigger: "no_credits" });
      return;
    }

    setIsLoading(true);
    setError("");
    setShowPreviewModal(true);

    try {
      trackFeatureUse("content_generation", {
        type: generationType,
        tag_count: tags.length,
      });

      // Only deduct credits if not unlimited and not -1
      if (!currentUser?.hasUnlimitedCredits && credits !== -1) {
        // Start Firestore update early
        const userRef = doc(db, "users", currentUser.uid);
        const updatePromise = updateDoc(userRef, {
          credits: increment(-1),
          lastGeneratedAt: new Date(),
        });

        // Process tags and generate content in parallel
        const processedTags = tags.slice(0, 3).map((tag) => tag.trim());
        const [result] = await Promise.all([
          generateContent(generationType, processedTags, PROMPT_TEMPLATE),
          updatePromise,
        ]);

        if (result) {
          const sanitizedContent = DOMPurify.sanitize(result);
          setGenerationProgress(100);
          setLoadingStage("Done!");

          // Update UI
          setGeneratedContent(sanitizedContent);
          setCredits((prev) => prev - 1);
        } else {
          throw new Error("No content received");
        }
      } else {
        // Unlimited credits or -1 credits, just generate content
        const processedTags = tags.slice(0, 3).map((tag) => tag.trim());
        const result = await generateContent(
          generationType,
          processedTags,
          PROMPT_TEMPLATE
        );

        if (result) {
          const sanitizedContent = DOMPurify.sanitize(result);
          setGenerationProgress(100);
          setLoadingStage("Done!");
          setGeneratedContent(sanitizedContent);
        } else {
          throw new Error("No content received");
        }
      }
    } catch (error) {
      console.error("Error generating content:", error);
      setError(
        error.message || "Failed to generate content. Please try again."
      );
      setGenerationProgress(0);
      setLoadingStage("");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveToLibrary = async (fromModal = false) => {
    try {
      trackFeatureUse("save_to_library", {
        from_modal: fromModal,
        content_type: generationType,
      });

      if (!currentUser) {
        throw new Error("You must be logged in to save ideas.");
      }

      const parser = new DOMParser();
      const docElement = parser.parseFromString(generatedContent, "text/html");
      const videoTitleElement = docElement.querySelector(
        "h2:first-of-type + p"
      );
      const videoTitle = videoTitleElement
        ? videoTitleElement.textContent.trim()
        : "Untitled Idea";

      const newIdea = {
        id: Date.now().toString(),
        title: videoTitle,
        content: generatedContent,
        date: new Date().toISOString(),
        tags: tags,
        generationType: generationType,
      };

      const userRef = doc(db, "users", currentUser.uid);

      await updateDoc(userRef, {
        ideas: arrayUnion(newIdea),
        ideasSaved: increment(1),
      });

      console.log("Idea saved successfully");

      if (fromModal) {
        setSaveSuccess(true);
        setSavedIdeaId(newIdea.id);
        setTimeout(() => setSaveSuccess(false), 5000);
      } else {
        setSaveSuccess(true);
        setSavedIdeaId(newIdea.id);
        setTimeout(() => setSaveSuccess(false), 5000);
      }
    } catch (error) {
      console.error("Error saving to library:", error);
      setError("Failed to save to library. Please try again.");
      trackError("save_to_library_failed", error.message);
    }
  };

  const handleViewInDashboard = () => {
    trackFeatureUse("view_in_dashboard");
    navigate("/idea-vault", { state: { highlightIdeaId: savedIdeaId } });
  };

  const handleClearIdea = () => {
    trackFeatureUse("clear_idea");
    setGeneratedContent(null);
    setSavedIdeaId(null);
    setSaveSuccess(false);
  };

  const handleRegenerateContent = () => {
    trackFeatureUse("regenerate_content", {
      type: generationType,
    });
    handleGenerate();
  };

  const renderPreviewContent = () => {
    const getProgressColor = (progress) => {
      if (progress < 30) return "#6a11cb";
      if (progress < 60) return "#2575fc";
      if (progress < 90) return "#11998e";
      return "#38ef7d";
    };

    // Calculate estimated time remaining based on current progress and total expected time
    const getEstimatedTimeRemaining = (progress) => {
      const totalExpectedTime = 16; // seconds
      const remainingPercentage = (90 - progress) / 90;
      const remainingSeconds = Math.ceil(
        totalExpectedTime * remainingPercentage
      );
      return remainingSeconds;
    };

    return (
      <div className="text-center p-3">
        <h4 className="mb-4">Generating Your Content</h4>
        <div className="progress mb-3" style={{ height: "20px" }}>
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{
              width: `${generationProgress}%`,
              transition: "width 0.15s linear",
              minWidth: "2em",
              background: `linear-gradient(45deg, ${getProgressColor(
                generationProgress
              )} 0%, ${getProgressColor(generationProgress + 30)} 100%)`,
            }}
            aria-valuenow={generationProgress}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {Math.round(generationProgress)}%
          </div>
        </div>
        {generationProgress < 100 ? (
          <>
            <p className="mb-2" style={{ fontWeight: "500" }}>
              {loadingStage}
            </p>
            <p className="text-muted small mb-0">
              Estimated time remaining:{" "}
              {getEstimatedTimeRemaining(generationProgress)}s
            </p>
          </>
        ) : (
          <>
            <p className="mb-3">Content Generated Successfully! 🎉</p>
            <Button
              variant="primary"
              onClick={() => setShowPreviewModal(false)}
            >
              View Content
            </Button>
          </>
        )}
      </div>
    );
  };

  const renderSubscriptionModalContent = () => {
    if (currentUser?.hasUnlimitedCredits || credits === -1) {
      return <p>You have unlimited credits!</p>;
    }
    return (
      <>
        <p>You're out of credits!</p>
        <p>
          Current balance:{" "}
          {currentUser?.hasUnlimitedCredits || credits === -1
            ? "Unlimited"
            : credits}{" "}
          credits
        </p>
        <p>Choose a plan to continue generating amazing content:</p>
        <ul>
          <li>Standard Plan: 30 credits/month for £8</li>
          <li>Premium Plan: Unlimited credits/month for £12</li>
        </ul>
      </>
    );
  };

  return (
    <Container fluid className="custom-vh ">
      <Container className="generate-ideas-container ">
        <Row>
          <Col md={6} className="mb-md-0 mb-4">
            <Card className="h-100 shadow">
              <Card.Body>
                <h2 className="text-center mb-4">Generate Content Ideas</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <p className="text-center">
                  Credits remaining:{" "}
                  {currentUser?.hasUnlimitedCredits || credits === -1
                    ? "Unlimited"
                    : credits}
                </p>
                <Form onSubmit={addTag}>
                  <Form.Group className="mb-3">
                    <Form.Label>Generation Type:</Form.Label>
                    <div className="d-flex justify-content-around mb-3">
                      <Button
                        variant={
                          generationType === "personal"
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => setGenerationType("personal")}
                      >
                        <FaPersonBooth className="me-2" /> Personal Brand
                      </Button>
                      <Button
                        variant={
                          generationType === "shop"
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => setGenerationType("shop")}
                      >
                        <FaStore className="me-2" /> TikTok Shop
                      </Button>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Add your personal tags:</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter a tag"
                      />
                      <Button
                        variant="primary"
                        onClick={addTag}
                        className="ms-2"
                      >
                        Add
                      </Button>
                    </div>
                  </Form.Group>
                  <ListGroup className="mb-3">
                    {tags.map((tag, index) => (
                      <ListGroup.Item
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {tag}
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => removeTag(index)}
                        >
                          Remove
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <Button
                    variant="primary"
                    onClick={handleGenerate}
                    className="w-100"
                    disabled={isLoading || tags.length === 0}
                  >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        <FaMagic className="me-2" /> Generate Content
                      </>
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="h-100 shadow">
              <Card.Body>
                <h2 className="text-center mb-4">Generated Content</h2>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                    <p className="mt-2">Generating content...</p>
                  </div>
                ) : generatedContent ? (
                  <>
                    {renderGeneratedContent()}
                    {saveSuccess && (
                      <Alert variant="success" className="mt-3">
                        Idea saved successfully!
                      </Alert>
                    )}
                    <div className="mt-3 d-flex justify-content-between flex-md-row flex-column">
                      <Button
                        variant="success"
                        onClick={() => handleSaveToLibrary(false)}
                        className="m-2"
                        disabled={isLoading}
                      >
                        <FaSave className="me-2" /> Save to Library
                      </Button>
                      <Button
                        variant="info"
                        onClick={handleRegenerateContent}
                        className="m-2"
                        disabled={isLoading}
                      >
                        <FaMagic className="me-2" /> Regenerate
                      </Button>
                      <Button
                        className="m-2"
                        variant="danger"
                        onClick={handleClearIdea}
                        disabled={isLoading}
                      >
                        <FaTrash className="me-2" /> Clear Idea
                      </Button>
                    </div>
                    {saveSuccess && (
                      <Button
                        variant="primary"
                        onClick={handleViewInDashboard}
                        className="w-100 mt-2"
                      >
                        <FaEye className="me-2" /> View in Dashboard
                      </Button>
                    )}
                  </>
                ) : (
                  <p className="text-muted text-center">
                    Generated content will appear here
                  </p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          show={showPreviewModal}
          onHide={() => setShowPreviewModal(false)}
          centered
          size="sm"
        >
          <Modal.Body className="px-2 py-4">
            {renderPreviewContent()}
          </Modal.Body>
        </Modal>
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={2000}
          autohide
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            zIndex: 9999,
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>Idea saved to library!</Toast.Body>
        </Toast>
        <Modal
          show={showSubscriptionModal}
          onHide={() => setShowSubscriptionModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Out of Credits</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderSubscriptionModalContent()}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShowSubscriptionModal(false);
                navigate("/pricing");
              }}
            >
              View Plans
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Container>
  );
}

export default GenerateIdeas;
