import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { trackEvent, trackError } from "../utils/analytics";
import { handleTikTokWebview } from "../utils/webviewHelper";

import headshot from "../imgs/headshot.jpeg";
import headshot2 from "../imgs/headshot2.jpeg";
import headshot3 from "../imgs/headshot3.png";
import headshot4 from "../imgs/headshot4.jpeg";
import planet from "../imgs/planet.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Process from "./Process";
import Pricing from "./Pricing";
import FAQ from "./Faq";
import ConsentPopup from "./ConsentPopup";
import TestimonialShowcase from "./TestimonialShowcase";
import TikTokConveyor from "./TikTokConveyor";

function Home() {
  const [user, setUser] = useState(null);
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [viewCount, setViewCount] = useState(5000000);
  const [showFirework, setShowFirework] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/generate");
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const duration = 2000;
    const startCount = 5000000;
    const finalCount = 10000000;
    const startTime = Date.now();

    const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeOutCubic(progress);

      const currentCount = Math.floor(
        startCount + (finalCount - startCount) * easedProgress
      );
      setViewCount(currentCount);

      if (progress >= 1) {
        clearInterval(timer);
        setShowFirework(true);
      }
    }, 16);

    return () => clearInterval(timer);
  }, []);

  const handleGetStarted = (e) => {
    e.preventDefault();

    if (handleTikTokWebview(setError)) {
      return;
    }

    // Add fade transition
    document.body.style.opacity = "0";
    document.body.style.transition = "opacity 0.3s ease";

    setTimeout(() => {
      // Navigate to pricing page to let users choose their plan
      navigate("/pricing");
      // Reset opacity after navigation
      document.body.style.opacity = "1";
    }, 300);
  };

  const handleConsentAccept = async () => {
    trackEvent("consent_action", {
      action: "accept",
      type: "mailing_list",
    });

    setShowConsentPopup(false);
    if (user && user.email) {
      try {
        const response = await fetch(
          "https://us-central1-content-gen-777b7.cloudfunctions.net/addToKlaviyoList",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: user.email }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(
            data.error || `HTTP error! status: ${response.status}`
          );
        }

        trackEvent("mailing_list_subscription", {
          status: "success",
        });

        console.log("Profile created and subscribed:", data);
        alert("Successfully subscribed to the mailing list!");
      } catch (error) {
        console.error("Error in subscription process:", error);
        trackError("mailing_list_subscription_failed", error.message);
        alert(
          "There was an error subscribing to the mailing list. Please try again later."
        );
      }
    }
  };

  const handleConsentDecline = () => {
    trackEvent("consent_action", {
      action: "decline",
      type: "mailing_list",
    });
    console.log("Consent declined");
    setShowConsentPopup(false);
  };

  const stars = useMemo(() => {
    return [...Array(500)].map((_, i) => ({
      key: i,
      size: ["small", "medium", "large"][Math.floor(Math.random() * 3)],
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      delay: `${Math.random() * 5}s`,
    }));
  }, []);

  return (
    <div className="home-container">
      <div className="starry-background">
        <Container
          fluid
          className="home-container position-relative d-md-flex align-items-center"
        >
          <Container>
            <Row className="d-flex align-items-center">
              <Col lg={6} className="text-left">
                <h1 className="landing-header">
                  More views, more sales
                  <span>Let's go viral!</span>
                </h1>
                <p className="landing-text my-4">
                  Viralz uses real-time data to generate viral hooks, hashtags,
                  scenarios, and more for personal brands, businesses, and
                  TikTok shops creators.
                </p>

                <div className="landing-btn-div">
                  <Button
                    className="landing-btn mb-4"
                    onClick={handleGetStarted}
                  >
                    Start 7-Day Free Trial <br />• Cancel Anytime
                  </Button>
                  <div
                    className={`view-count-container ${
                      showFirework ? "firework-active" : ""
                    }`}
                  >
                    <p className="view-count-text small">
                      We generate {viewCount.toLocaleString()}+ views each month
                    </p>
                  </div>
                </div>

                <div className="circle-logos-container mt-5 ps-3">
                  {[headshot, headshot3, headshot2, headshot4].map(
                    (logo, index) => (
                      <img
                        key={index}
                        src={logo}
                        alt={`Logo ${index}`}
                        className="circle-logo"
                      />
                    )
                  )}
                  <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                </div>

                <p className="trusted-text text-left mt-3">
                  1249 Content Ideas Generated for 300+ Satisfied Customers
                </p>
              </Col>
              <Col
                lg={6}
                className="d-none d-md-flex align-items-center justify-content-center"
              >
                <div className="planet-system">
                  <img src={planet} alt="Planet" className="planet" />
                  <div className="moon"></div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="planet-system-mobile d-md-none">
            <img src={planet} alt="Planet" className="planet" />
            <div className="moon"></div>
          </div>
        </Container>
      </div>

      <div className="starry-sections">
        <div className="stars">
          {stars.map((star) => (
            <div
              key={star.key}
              className={`star star-${star.size}`}
              style={{
                top: star.top,
                left: star.left,
                animationDelay: star.delay,
              }}
            ></div>
          ))}
        </div>
        <section id="tiktok-showcase" className="my-5">
          <TikTokConveyor />
        </section>
        <section id="about">
          <Process />
        </section>
        <TestimonialShowcase />
        <section id="pricing">
          <Pricing />
        </section>
        <section id="faq">
          <FAQ />
        </section>
      </div>

      {showConsentPopup && (
        <ConsentPopup
          onAccept={handleConsentAccept}
          onDecline={handleConsentDecline}
        />
      )}

      {error && (
        <div
          className="position-fixed top-50 start-50 translate-middle"
          style={{ zIndex: 1050 }}
        >
          <div className="bg-white p-4 rounded shadow-lg">{error}</div>
        </div>
      )}
    </div>
  );
}

export default Home;
