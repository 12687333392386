import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  Navbar,
  Col,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import ContactForm from "./ContactForm";
import { FaUserCircle, FaTimes, FaCreditCard } from "react-icons/fa";
import logo from "../imgs/logo.png";
import { motion, AnimatePresence } from "framer-motion";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { trackEvent, trackError } from "../utils/analytics";

function Navigation() {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [hasStripeCustomer, setHasStripeCustomer] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const checkStripeCustomer = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        setHasStripeCustomer(
          userDoc.exists() && userDoc.data().stripeCustomerId
        );
      }
    };
    checkStripeCustomer();
  }, [currentUser]);

  const closeNav = () => setExpanded(false);

  const handleNavTransition = (e, targetPath) => {
    e.preventDefault();
    closeNav();

    // For hash links (same page navigation), use smooth scroll without transition
    if (targetPath.startsWith("/#")) {
      const sectionId = targetPath.substring(2);
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    // For different page navigation, use fade transition
    document.body.style.opacity = "0";
    document.body.style.transition = "opacity 0.3s ease";

    setTimeout(() => {
      navigate(targetPath);
      document.body.style.opacity = "1";
    }, 300);
  };

  const handleNavClick = (e, target) => {
    e.preventDefault();
    closeNav();

    trackEvent("navigation", {
      target: target,
      from: location.pathname,
    });

    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(target);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const element = document.getElementById(target);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleContactClick = () => {
    trackEvent("contact_modal_open");
    closeNav();
    setShowContactModal(true);
  };

  const handleLogout = async () => {
    try {
      trackEvent("auth_action", { action: "logout" });
      document.body.style.opacity = "0";
      document.body.style.transition = "opacity 0.3s ease";

      setTimeout(async () => {
        await logout();
        navigate("/");
        document.body.style.opacity = "1";
      }, 300);
    } catch (error) {
      console.error("Failed to log out", error);
      trackError("logout_failed", error.message);
    }
  };

  const redirectToCustomerPortal = async () => {
    try {
      trackEvent("customer_portal_access", {
        userId: currentUser.uid,
      });

      const userRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      if (!userData.hasSubscription || !userData.stripeCustomerId) {
        trackEvent("subscription_redirect", {
          from: "customer_portal",
          to: "pricing",
        });
        navigate("/pricing");
        return;
      }

      console.log("Requesting portal session for user:", currentUser.uid);
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/createCustomerPortalSession`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: currentUser.uid }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Portal session creation failed:", errorData);
        throw new Error(errorData.details || "Failed to create portal session");
      }

      const { url } = await response.json();
      console.log("Redirecting to portal URL:", url);
      window.location.href = url;
    } catch (error) {
      console.error("Error accessing customer portal:", error);
      trackError("customer_portal_access_failed", error.message);
      alert(
        "Unable to access customer portal. Please try again later. Error: " +
          error.message
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/";

  const navStyle = {
    background: "linear-gradient(to bottom, #4b0082, #000000)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    ...(currentUser ? {} : { position: "absolute" }),
    marginTop: "0",
  };

  const navVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const menuItemVariants = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const handleLogin = () => {
    try {
      navigate("/login?mode=login");
      closeNav();
    } catch (error) {
      console.error("Login error:", error);
      setError(error.message);
    }
  };

  return (
    <>
      <motion.div
        className={`nav-wrapper ${isHomePage ? "home-nav" : ""}`}
        style={navStyle}
        initial="hidden"
        animate="visible"
        variants={navVariants}
      >
        {isHomePage && (
          <div className="stars">
            {[...Array(100)].map((_, i) => (
              <div
                key={i}
                className={`star star-${
                  ["small", "medium", "large"][Math.floor(Math.random() * 3)]
                }`}
                style={{
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  animationDelay: `${Math.random() * 5}s`,
                }}
              ></div>
            ))}
          </div>
        )}
        <Col md={12} className="nav-col">
          <Navbar expand="lg" variant="dark" expanded={expanded}>
            <Container>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Navbar.Brand
                  as={Link}
                  to={currentUser ? "/generate" : "/register?trial=premium"}
                  onClick={closeNav}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    className="img-fluid"
                    width={200}
                    height={50}
                  />
                </Navbar.Brand>
              </motion.div>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(expanded ? false : "expanded")}
                className="nav-toggle"
              >
                {expanded ? (
                  <FaTimes />
                ) : (
                  <span className="navbar-toggler-icon" />
                )}
              </Navbar.Toggle>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={`fullscreen-menu ${expanded ? "show" : ""}`}
              >
                <AnimatePresence>
                  <Nav className="ms-lg-auto" key="main-nav">
                    {currentUser ? (
                      <>
                        <motion.div variants={menuItemVariants} key="generate">
                          <Nav.Link
                            as={Link}
                            to="/generate"
                            className="mx-md-3 my-3 mx-0 link"
                            onClick={closeNav}
                          >
                            Generate
                          </Nav.Link>
                        </motion.div>
                        <motion.div
                          variants={menuItemVariants}
                          key="idea-vault"
                        >
                          <Nav.Link
                            as={Link}
                            to="/idea-vault"
                            className="mx-md-3 my-3 mx-0 link"
                            onClick={closeNav}
                          >
                            Idea Vault
                          </Nav.Link>
                        </motion.div>
                      </>
                    ) : (
                      <>
                        <motion.div variants={menuItemVariants} key="home">
                          <Nav.Link
                            onClick={(e) =>
                              handleNavTransition(
                                e,
                                currentUser
                                  ? "/generate"
                                  : "/register?trial=premium"
                              )
                            }
                            className="mx-md-3 my-3 mx-0 link nav-link"
                          >
                            Home
                          </Nav.Link>
                        </motion.div>
                        <motion.div variants={menuItemVariants} key="about">
                          <Nav.Link
                            href="/#about"
                            className="mx-md-3 my-3 mx-0 link"
                            onClick={(e) => handleNavClick(e, "about")}
                          >
                            About
                          </Nav.Link>
                        </motion.div>
                        <motion.div variants={menuItemVariants} key="pricing">
                          <Nav.Link
                            href="/#pricing"
                            className="mx-md-3 my-3 mx-0 link"
                            onClick={(e) => handleNavClick(e, "pricing")}
                          >
                            Pricing
                          </Nav.Link>
                        </motion.div>
                        <motion.div variants={menuItemVariants} key="faq">
                          <Nav.Link
                            href="/#faq"
                            className="mx-md-3 my-3 mx-0 link"
                            onClick={(e) => handleNavClick(e, "faq")}
                          >
                            FAQ
                          </Nav.Link>
                        </motion.div>
                      </>
                    )}
                    <motion.div variants={menuItemVariants} key="contact">
                      <Nav.Link
                        className="mx-md-3 my-3 mx-0 link"
                        onClick={handleContactClick}
                      >
                        Contact
                      </Nav.Link>
                    </motion.div>
                  </Nav>
                  <Nav key="auth-nav">
                    {currentUser ? (
                      <>
                        <div
                          className="d-lg-none text-center"
                          key="mobile-menu"
                        >
                          <Nav.Link
                            onClick={redirectToCustomerPortal}
                            className="mx-md-3 my-3 mx-0 link"
                          >
                            Manage Subscription
                          </Nav.Link>
                          <Nav.Link
                            onClick={handleLogout}
                            className="mx-md-3 my-3 mx-0 link"
                          >
                            Logout
                          </Nav.Link>
                        </div>
                        <div className="d-none d-lg-block" key="desktop-menu">
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              variant="outline-light"
                              id="dropdown-basic"
                            >
                              <FaUserCircle className="me-2" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {hasStripeCustomer && (
                                <Dropdown.Item
                                  onClick={redirectToCustomerPortal}
                                >
                                  <FaCreditCard className="me-2" /> Manage
                                  Subscription
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item onClick={handleLogout}>
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    ) : (
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        key="login-button"
                      >
                        <Button variant="outline-light" onClick={handleLogin}>
                          Login
                        </Button>
                      </motion.div>
                    )}
                  </Nav>
                </AnimatePresence>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
      </motion.div>

      <Modal
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm onSubmitSuccess={() => setShowContactModal(false)} />
        </Modal.Body>
      </Modal>

      {error && (
        <div
          className="position-fixed top-50 start-50 translate-middle"
          style={{ zIndex: 1050 }}
        >
          <div className="bg-white p-4 rounded shadow-lg">{error}</div>
        </div>
      )}
    </>
  );
}
export default Navigation;
