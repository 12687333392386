import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow">
            <Card.Body className="p-5">
              <h1 className="text-center mb-4">Privacy Policy</h1>
              <p className="text-muted mb-4">
                Last updated: {new Date().toLocaleDateString()}
              </p>

              <section className="mb-4">
                <h2>1. Introduction</h2>
                <p>
                  Welcome to Content Generator. We respect your privacy and are
                  committed to protecting your personal data. This privacy
                  policy will inform you about how we look after your personal
                  data when you visit our website and tell you about your
                  privacy rights and how the law protects you.
                </p>
              </section>

              <section className="mb-4">
                <h2>2. Data We Collect</h2>
                <p>We collect and process the following data:</p>
                <ul>
                  <li>Identity Data: name, username</li>
                  <li>Contact Data: email address</li>
                  <li>
                    Technical Data: IP address, browser type and version, time
                    zone setting
                  </li>
                  <li>
                    Usage Data: information about how you use our website and
                    services
                  </li>
                  <li>
                    Generated Content: The content you generate using our
                    services
                  </li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>3. How We Use Your Data</h2>
                <p>We use your data for the following purposes:</p>
                <ul>
                  <li>To provide and maintain our service</li>
                  <li>To notify you about changes to our service</li>
                  <li>To provide customer support</li>
                  <li>
                    To gather analysis or valuable information to improve our
                    service
                  </li>
                  <li>To detect, prevent and address technical issues</li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>4. Data Storage and Security</h2>
                <p>
                  We implement appropriate security measures to protect your
                  personal data against unauthorized access, alteration,
                  disclosure, or destruction. Your data is stored securely using
                  industry-standard encryption and security measures.
                </p>
              </section>

              <section className="mb-4">
                <h2>5. Your Rights</h2>
                <p>Under data protection laws, you have rights including:</p>
                <ul>
                  <li>Your right of access</li>
                  <li>Your right to rectification</li>
                  <li>Your right to erasure</li>
                  <li>Your right to restrict processing</li>
                  <li>Your right to data portability</li>
                  <li>Your right to object</li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>6. Third-Party Services</h2>
                <p>We use the following third-party services:</p>
                <ul>
                  <li>Firebase - For authentication and data storage</li>
                  <li>Stripe - For payment processing</li>
                  <li>Analytics services - To improve our service</li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>7. Cookies</h2>
                <p>
                  We use cookies and similar tracking technologies to track
                  activity on our service and hold certain information. Cookies
                  are files with small amount of data which may include an
                  anonymous unique identifier.
                </p>
              </section>

              <section className="mb-4">
                <h2>8. Changes to This Policy</h2>
                <p>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page and updating the "Last updated" date at the top of
                  this policy.
                </p>
              </section>

              <section>
                <h2>9. Contact Us</h2>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us:
                </p>
                <ul>
                  <li>By email: viralzmanagement@gmail.com</li>
                  <li>By visiting our contact page on our website</li>
                </ul>
              </section>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPolicy;
