import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { FaGoogle, FaEnvelope, FaLock, FaExchangeAlt } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { isInWebview } from "../utils/webviewHelper";

// Pricing plans data
const pricingOptions = [
  {
    id: 1,
    title: "Standard Membership",
    subtitle: "£8/month",
    tag: "7-Day Free Trial",
    description: "30 credits per month",
    priceId: process.env.REACT_APP_STANDARD_TIER_PRICE_ID,
  },
  {
    id: 2,
    title: "Premium Membership",
    subtitle: "£12/month",
    tag: "7-Day Free Trial - Best Value",
    description: "Unlimited credits per month",
    priceId: process.env.REACT_APP_PREMIUM_TIER_PRICE_ID,
  },
];

function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const { login: signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isWebview, setIsWebview] = useState(false);

  useEffect(() => {
    setIsWebview(isInWebview());
  }, []);

  // Get mode and selected plan from URL
  const params = new URLSearchParams(location.search);
  const [isLogin, setIsLogin] = useState(
    () => params.get("mode") !== "register"
  );
  const [selectedPriceId, setSelectedPriceId] = useState(params.get("priceId"));

  // Find selected plan details
  const selectedPlan = pricingOptions.find(
    (plan) => plan.priceId === selectedPriceId
  );

  // Function to switch plans
  const handleSwitchPlan = () => {
    const currentIndex = pricingOptions.findIndex(
      (plan) => plan.priceId === selectedPriceId
    );
    const nextIndex = (currentIndex + 1) % pricingOptions.length;
    setSelectedPriceId(pricingOptions[nextIndex].priceId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Validate passwords match for registration
    if (!isLogin && password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      if (isLogin) {
        // Handle login
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        if (!userCredential.user.emailVerified) {
          await sendEmailVerification(userCredential.user);
          setVerificationSent(true);
          setLoading(false);
          return;
        }
        // Only navigate if email is verified
        navigate("/generate");
      } else {
        // Handle registration
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Send verification email immediately after registration
        await sendEmailVerification(userCredential.user);

        // Create user document
        const userRef = doc(db, "users", userCredential.user.uid);
        await setDoc(userRef, {
          email: email.toLowerCase(),
          createdAt: new Date(),
          emailVerified: false,
          credits: 3,
          hasSubscription: false,
          selectedPriceId: selectedPriceId, // Store the selected plan
        });

        // Always show verification screen for new registrations
        setVerificationSent(true);
        setMessage("Please verify your email to continue.");
      }
    } catch (error) {
      console.error("Auth error:", error);
      if (error.code === "auth/email-already-in-use") {
        const methods = await fetchSignInMethodsForEmail(auth, email);
        if (methods.includes("google.com")) {
          setError(
            "This email is associated with a Google account. Please sign in with Google."
          );
        } else {
          setError(
            "An account with this email already exists. Please sign in instead."
          );
        }
      } else if (error.code === "auth/wrong-password") {
        setError("Incorrect password. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        setError("No account found with this email. Please sign up first.");
      } else if (error.code === "auth/invalid-email") {
        setError("Invalid email address. Please check and try again.");
      } else {
        setError(error.message);
      }
    }

    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      setError("");
      setLoading(true);
      await signInWithGoogle();
      // Keep original Google sign-in flow
      navigate("/generate");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const switchMode = () => {
    setIsLogin(!isLogin);
    setError("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  // If verification email is sent, show verification screen
  if (verificationSent) {
    return (
      <Container className="d-flex align-items-center justify-content-center min-vh-100">
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body className="text-center">
              <h2 className="mb-4">Verify Your Email</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <p>We've sent a verification email to:</p>
              <p className="fw-bold">{email}</p>
              <p>
                Please check your inbox and click the verification link to
                continue.
              </p>
              {selectedPlan && (
                <div className="mt-3 mb-3 p-3 bg-light rounded">
                  <h5>Selected Plan: {selectedPlan.title}</h5>
                  <p className="mb-0">{selectedPlan.description}</p>
                </div>
              )}
              <p className="text-muted">
                After verifying your email, please sign in again to complete
                your registration.
              </p>
              <Button
                variant="primary"
                className="w-100 mt-3"
                onClick={async () => {
                  try {
                    await sendEmailVerification(auth.currentUser);
                    setMessage("Verification email resent!");
                    setError("");
                  } catch (error) {
                    setError(
                      "Failed to resend verification email. Please try again."
                    );
                    setMessage("");
                  }
                }}
              >
                Resend Verification Email
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Container>
    );
  }

  return (
    <Container className="d-flex align-items-center justify-content-center flex-column auth-container">
      <Row className="w-100 justify-content-center">
        <Col xs={12} md={8} lg={7} className="px-lg-5">
          <Card
            className="auth-card shadow-lg"
            style={{
              background: "linear-gradient(145deg, #1a1a1a, #2d2d2d)",
              border: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <Card.Body className="p-4 px-lg-5">
              <div className="text-center mb-4">
                <h2 className="auth-title text-white">
                  {isLogin ? "Welcome Back!" : "Create Account"}
                </h2>
                <p className="text-light opacity-75">
                  {isLogin
                    ? "Sign in to continue to your account"
                    : "Get started with your free account"}
                </p>
              </div>

              {error && (
                <Alert
                  variant={error.includes("verify") ? "info" : "danger"}
                  className="text-center"
                >
                  {error}
                </Alert>
              )}

              <Form onSubmit={handleSubmit} className="auth-form">
                <Form.Group className="mb-3">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{
                        background: "#2d2d2d",
                        borderColor: "rgba(255,255,255,0.1)",
                        color: "#fff",
                      }}
                    >
                      <FaEnvelope />
                    </span>
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      style={{
                        background: "#2d2d2d",
                        borderColor: "rgba(255,255,255,0.1)",
                        color: "#fff",
                      }}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{
                        background: "#2d2d2d",
                        borderColor: "rgba(255,255,255,0.1)",
                        color: "#fff",
                      }}
                    >
                      <FaLock />
                    </span>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      style={{
                        background: "#2d2d2d",
                        borderColor: "rgba(255,255,255,0.1)",
                        color: "#fff",
                      }}
                    />
                  </div>
                </Form.Group>

                {!isLogin && (
                  <Form.Group className="mb-3">
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        style={{
                          background: "#2d2d2d",
                          borderColor: "rgba(255,255,255,0.1)",
                          color: "#fff",
                        }}
                      >
                        <FaLock />
                      </span>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        style={{
                          background: "#2d2d2d",
                          borderColor: "rgba(255,255,255,0.1)",
                          color: "#fff",
                        }}
                      />
                    </div>
                  </Form.Group>
                )}

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mb-3"
                  disabled={loading}
                  style={{
                    background: "linear-gradient(45deg, #4299e1, #667eea)",
                    border: "none",
                    padding: "12px",
                  }}
                >
                  {loading
                    ? "Please wait..."
                    : isLogin
                    ? "Sign In"
                    : "Create Account"}
                </Button>

                {!isWebview && isLogin && (
                  <>
                    <div className="text-center mb-3">
                      <span className="divider-text text-light opacity-75">
                        or continue with
                      </span>
                    </div>

                    <Button
                      variant="outline-light"
                      className="w-100 mb-3"
                      onClick={handleGoogleSignIn}
                      disabled={loading}
                      style={{
                        borderColor: "rgba(255,255,255,0.2)",
                        padding: "12px",
                      }}
                    >
                      <FaGoogle className="me-2" /> Google
                    </Button>
                  </>
                )}

                <div className="text-center mt-4 text-light">
                  {isLogin ? (
                    <>
                      Don't have an account?{" "}
                      <Button
                        variant="link"
                        onClick={switchMode}
                        className="auth-switch-btn text-primary px-2"
                      >
                        Sign up
                      </Button>
                    </>
                  ) : (
                    <>
                      Already have an account?{" "}
                      <Button
                        variant="link"
                        onClick={switchMode}
                        className="auth-switch-btn text-primary px-2"
                      >
                        Sign in
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            </Card.Body>
          </Card>

          {/* Separate card for membership selection */}
          {!isLogin && selectedPlan && (
            <Card
              className="membership-card shadow-lg mt-4"
              style={{
                background: "linear-gradient(145deg, #1a1a1a, #2d2d2d)",
                border: "1px solid rgba(255,255,255,0.1)",
              }}
            >
              <Card.Body className="p-4 px-lg-5">
                <h4 className="mb-3 text-white">Selected Membership</h4>
                <div
                  className="selected-plan p-3 rounded"
                  style={{
                    border: "1px solid rgba(255,255,255,0.1)",
                    background: "rgba(45,45,45,0.5)",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className="mb-3 mb-md-0">
                      <h5 className="mb-1 text-white">{selectedPlan.title}</h5>
                      <p className="mb-1 text-light">{selectedPlan.subtitle}</p>
                      <small className="text-light opacity-75">
                        {selectedPlan.description}
                      </small>
                    </div>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleSwitchPlan}
                      className="ms-md-3"
                      style={{
                        borderColor: "rgba(66, 153, 225, 0.5)",
                        color: "#4299e1",
                      }}
                    >
                      <FaExchangeAlt className="me-1" /> Switch Plan
                    </Button>
                  </div>
                  {selectedPlan.tag && (
                    <div className="mt-2">
                      <span
                        className="badge"
                        style={{
                          background:
                            "linear-gradient(45deg, #4299e1, #667eea)",
                          boxShadow: "0 2px 4px rgba(66, 153, 225, 0.3)",
                        }}
                      >
                        {selectedPlan.tag}
                      </span>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Auth;
