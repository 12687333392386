import { getFunctions, httpsCallable } from "firebase/functions";

export const generateContent = async (generationType, tags, prompt) => {
  try {
    const functions = getFunctions();
    const generateWithOpenAI = httpsCallable(functions, "generateWithOpenAI", {
      timeout: 30000,
    });

    const result = await generateWithOpenAI({
      generationType,
      tags,
      prompt,
    });

    if (!result?.data?.content) {
      throw new Error("No content received from the server");
    }

    return result.data.content;
  } catch (error) {
    console.error("Generation Error:", error);
    if (error.message.includes("deadline-exceeded")) {
      throw new Error("Generation took too long. Please try again.");
    }
    throw error;
  }
};
