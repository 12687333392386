import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, signInWithGoogle, logOut, db } from "../firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userCredits, setUserCredits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          if (!userDoc.exists()) {
            await setDoc(userRef, {
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
              createdAt: new Date(),
              freeCreditsUsed: false,
              hasSubscription: false,
              credits: 3,
              freeCreditsExpiration: new Date(
                Date.now() + 14 * 24 * 60 * 60 * 1000
              ),
            });
            setUserCredits(3);
          } else {
            const userData = userDoc.data();
            if (userData.freeCreditsExpiration && !userData.hasSubscription) {
              const now = new Date();
              const expirationDate = userData.freeCreditsExpiration.toDate();
              if (now > expirationDate) {
                await setDoc(userRef, { credits: 0 }, { merge: true });
                setUserCredits(0);
              } else {
                setUserCredits(userData.credits || 0);
              }
            } else {
              setUserCredits(userData.credits || 0);
            }
          }
          setCurrentUser(user);
        } catch (error) {
          console.error("Error checking/creating user document:", error);
        }
      } else {
        setCurrentUser(null);
        setUserCredits(0);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const login = async () => {
    try {
      setError(null);
      return signInWithGoogle();
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  const loginWithEmail = async (email, password) => {
    try {
      setError(null);
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  const registerWithEmail = async (email, password) => {
    try {
      setError(null);
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Create user document
      const userRef = doc(db, "users", result.user.uid);
      await setDoc(userRef, {
        email: result.user.email,
        createdAt: new Date(),
        freeCreditsUsed: false,
        hasSubscription: false,
        credits: 3,
        freeCreditsExpiration: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
      });
      return result;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  function logout() {
    setUserCredits(0);
    return logOut();
  }

  const value = {
    currentUser,
    userCredits,
    setUserCredits,
    login,
    loginWithEmail,
    registerWithEmail,
    logout,
    loading,
    error,
    setError,
  };

  if (loading) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
