import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Nav,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { FaGoogle, FaCrown, FaCreditCard, FaUserCircle } from "react-icons/fa";

function Profile() {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState("subscription");
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserDetails({ ...userSnap.data(), email: currentUser.email });
          }
        } catch (err) {
          setError("Failed to load user details");
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [currentUser]);

  const redirectToCustomerPortal = () => {
    // Replace with your actual Stripe Customer Portal URL
    const stripeCustomerPortalUrl =
      "https://billing.stripe.com/p/login/test_00g6qMaNn544eu4000";
    window.location.href = stripeCustomerPortalUrl;
  };

  if (loading)
    return (
      <div className="text-center mt-5">
        <h3>Loading...</h3>
      </div>
    );
  if (error)
    return (
      <Alert variant="danger" className="mt-3">
        {error}
      </Alert>
    );
  if (!userDetails)
    return (
      <div className="text-center mt-5">
        <h3>No user details found</h3>
      </div>
    );

  return (
    <Container fluid className="vh-100 custom-vh">
      <Container className="profile-container mt-5 pt-5">
        <Row>
          <Col md={3}>
            <Card className="profile-sidebar mb-4">
              <Card.Body>
                <div className="text-center mb-4">
                  <FaUserCircle size={80} className="text-primary mb-3" />
                  <h4>{currentUser.displayName || currentUser.email}</h4>
                  <p className="membership-level">
                    {userDetails.membershipLevel || "Free Tier"}
                  </p>
                </div>
                <Nav className="flex-column profile-nav">
                  <Nav.Link
                    onClick={() => setActiveTab("subscription")}
                    active={activeTab === "subscription"}
                  >
                    <FaCrown className="me-2" /> Subscription
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => setActiveTab("account")}
                    active={activeTab === "account"}
                  >
                    <FaUserCircle className="me-2" /> Account Information
                  </Nav.Link>
                </Nav>
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Card className="profile-content">
              <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}

                {activeTab === "subscription" && (
                  <>
                    <h3 className="mb-4">Subscription Details</h3>
                    <ListGroup className="mb-4">
                      <ListGroup.Item>
                        <strong>Current Plan:</strong>{" "}
                        {userDetails.membershipLevel || "Free Tier"}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Status:</strong>{" "}
                        {userDetails.subscriptionStatus || "Not subscribed"}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Credits:</strong> {userDetails.credits || 0}
                        <ProgressBar
                          now={userDetails.credits}
                          max={100}
                          variant="info"
                          className="mt-2"
                        />
                      </ListGroup.Item>
                    </ListGroup>

                    <Button
                      variant="primary"
                      onClick={redirectToCustomerPortal}
                      className="mb-3"
                    >
                      <FaCreditCard className="me-2" /> Manage Subscription
                    </Button>

                    <p>
                      Click the button above to manage your subscription, update
                      payment methods, and view billing history.
                    </p>
                  </>
                )}

                {activeTab === "account" && (
                  <>
                    <h3 className="mb-4">Account Information</h3>
                    <ListGroup>
                      <ListGroup.Item>
                        <strong>Email:</strong> {currentUser.email}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Sign-in Method:</strong> <FaGoogle /> Google
                      </ListGroup.Item>
                    </ListGroup>
                    <p className="mt-3">
                      Your account is managed through Google. To update your
                      personal information, please visit your Google account
                      settings.
                    </p>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Profile;
