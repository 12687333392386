import { analytics } from "../firebaseConfig";
import { logEvent } from "firebase/analytics";

// Track page views
export const trackPageView = (pageName) => {
  logEvent(analytics, "page_view", {
    page_title: pageName,
    page_location: window.location.href,
    page_path: window.location.pathname,
  });
};

// Track user actions
export const trackEvent = (eventName, eventParams = {}) => {
  logEvent(analytics, eventName, eventParams);
};

// Common events
export const trackButtonClick = (buttonName, additionalParams = {}) => {
  trackEvent("button_click", {
    button_name: buttonName,
    ...additionalParams,
  });
};

export const trackFeatureUse = (featureName, additionalParams = {}) => {
  trackEvent("feature_use", {
    feature_name: featureName,
    ...additionalParams,
  });
};

export const trackError = (errorMessage, errorCode) => {
  trackEvent("error", {
    error_message: errorMessage,
    error_code: errorCode,
  });
};
