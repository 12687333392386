import React from "react";
import { Container } from "react-bootstrap";
import { FaArrowUp, FaPlay, FaChartLine, FaTiktok } from "react-icons/fa";
import ccf from "../imgs/ccf.jpeg";
import nc from "../imgs/nc.jpeg";
import si from "../imgs/si.jpeg";
import dtb from "../imgs/dtb.jpeg";

const TikTokConveyor = () => {
  const reviews = [
    {
      videoId: "7414833675923246368",
      creator: "Culture Clash Family",
      tiktokUsername: "cultureclashfamily",
      creatorImage: ccf,
      growthMetric: "12m",
      niche: "Views in 30 days",
      timeframe: "30 days",
      metrics: {
        views: "12M",
        gmv: "£18,264",
      },
    },
    {
      videoId: "7414833675923246369",
      creator: "Nayah Cosmetics",
      tiktokUsername: "nayah.cosmetics",
      creatorImage: nc,
      growthMetric: "324k",
      niche: "Views in 30 days",
      timeframe: "30 days",
      metrics: {
        views: "324K",
        gmv: "£21,340",
      },
    },
    {
      videoId: "7414833675923246370",
      creator: "Shop Ibrah",
      tiktokUsername: "shop.ibrah",
      creatorImage: si,
      growthMetric: "193k",
      niche: "Views in 30 days",
      timeframe: "30 days",
      metrics: {
        views: "193K",
        gmv: "£3,450",
      },
    },
    {
      videoId: "7414833675923246371",
      creator: "Dan The Brand",
      tiktokUsername: "danthebrand",
      creatorImage: dtb,
      growthMetric: "363k",
      niche: "Views in 30 days",
      timeframe: "30 days",
      metrics: {
        views: "363K",
        gmv: "N/A",
      },
    },
  ];

  return (
    <div className="tiktok-showcase">
      <Container>
        <div className="showcase-header">
          <span className="showcase-subtitle">TRUSTED BY CREATORS</span>
          <h2 className="showcase-title">Real Results from Real Creators</h2>
        </div>

        <div className="showcase-grid">
          {reviews.map((review, index) => (
            <div key={index} className="showcase-card">
              <div className="card-top">
                <div className="creator-info">
                  <div className="creator-avatar-wrapper">
                    <img
                      src={review.creatorImage}
                      alt={review.creator}
                      className="creator-avatar"
                    />
                    <div className="creator-platform">
                      <FaTiktok />
                    </div>
                  </div>
                  <div>
                    <h3 className="creator-name">{review.creator}</h3>
                    <span className="creator-niche">{review.niche}</span>
                  </div>
                </div>
                <div className="growth-badge">
                  <FaArrowUp />
                  <span>{review.growthMetric}</span>
                </div>
              </div>

              <div className="metrics-bar">
                <div className="metric">
                  <div className="metric-label">
                    <FaPlay className="metric-icon" />
                    <span>Views</span>
                  </div>
                  <div className="metric-value">{review.metrics.views}</div>
                </div>
                <div className="metric">
                  <div className="metric-label">
                    <FaChartLine className="metric-icon" />
                    <span>GMV</span>
                  </div>
                  <div className="metric-value">{review.metrics.gmv}</div>
                </div>
              </div>

              <div className="card-footer">
                <div className="timeframe">
                  Results in <span>{review.timeframe}</span>
                </div>
                <a
                  href={`https://www.tiktok.com/@${review.tiktokUsername}`}
                  className="view-story"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Profile
                </a>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default TikTokConveyor;
