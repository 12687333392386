import React from "react";
import { Container, Accordion } from "react-bootstrap";
import { trackEvent } from "../utils/analytics";

const FAQ = () => {
  const faqData = [
    {
      id: 1,
      question: "What services does Viralz offer?",
      answer:
        "Viralz provides AI-generated content ideas and scripts tailored for your specific niche, helping you stay relevant and engage with your target audience on platforms like TikTok and other social media.",
    },
    {
      id: 2,
      question: "How does the AI generate content ideas?",
      answer:
        "Our AI uses advanced algorithms to analyze trends, audience preferences, and your niche to create unique content ideas that resonate with your target audience.",
    },
    {
      id: 3,
      question: "Can I customize the AI-generated content?",
      answer:
        "Yes, the content ideas and scripts generated by our AI can be customized to better fit your brand voice, style, and specific content goals.",
    },
    {
      id: 4,
      question: "What platforms are supported by Viralz?",
      answer:
        "Viralz primarily focuses on TikTok, but our AI-generated ideas and scripts can be adapted for other platforms such as Instagram Reels, YouTube Shorts, and more.",
    },
    {
      id: 5,
      question: "How often can I generate new content ideas?",
      answer:
        "With Viralz, you can generate new content ideas as often as your subscription plan allows. We offer different tiers with varying numbers of monthly credits, so you can choose the plan that best fits your content creation needs.",
    },
    {
      id: 6,
      question: "What is your refund policy?",
      answer:
        "We offer a satisfaction guarantee. If you're not completely satisfied with your first batch of content ideas, you can request a refund for that month's subscription.",
    },
    {
      id: 7,
      question: "Can I request specific types of content ideas?",
      answer:
        "Absolutely! You can specify the type of content you're looking for, whether it's educational, entertaining, or promotional. Our AI will tailor the ideas accordingly.",
    },
    {
      id: 8,
      question: "Does Viralz offer a free trial?",
      answer:
        "Yes, we offer a free tier that provides a limited number of credits for AI-generated content ideas, allowing you to experience our service before subscribing to a paid plan.",
    },
    {
      id: 9,
      question: "How do I get started with Viralz?",
      answer:
        "Getting started is easy! Simply sign up on our website, choose your subscription plan, and start generating AI-powered content ideas that align with your goals and niche. Our user-friendly dashboard will guide you through the process.",
    },
    {
      id: 10,
      question: "Is my data secure with Viralz?",
      answer:
        "Absolutely. At Viralz, we prioritize your privacy and data security. All data interactions are encrypted, and we comply with strict data protection regulations to ensure your content ideas and account information are safe and confidential.",
    },
  ];

  const handleFaqClick = (question) => {
    trackEvent("faq_interaction", {
      question: question,
      location: "home_page",
    });
  };

  return (
    <Container className="my-5">
      <h2 className="mb-3">Frequently Asked Questions</h2>
      <Accordion defaultActiveKey="0">
        {faqData.map((faq, index) => (
          <Accordion.Item
            eventKey={`${index}`}
            key={faq.id}
            onClick={() => handleFaqClick(faq.question)}
          >
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>{faq.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default FAQ;
