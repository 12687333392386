import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { auth } from "../firebaseConfig";

const ContactForm = ({ onSubmitSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    emailjs.init("user_BBq1wqUbR0NpeCxXj9acX"); // Replace with your actual public key

    // Set user's email if they're logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.email) {
        setFormData((prev) => ({ ...prev, email: user.email }));
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setSubmitStatus("sending");

      emailjs
        .send(
          "service_3z6t7ek", // Replace with your EmailJS service ID
          "template_xpg0xms", // Replace with your EmailJS template ID
          {
            from_name: formData.name,
            from_email: formData.email,
            message: formData.message,
            to_email: "tausifmeah01@gmail.com",
          }
        )
        .then((response) => {
          console.log("Email sent successfully:", response);
          setSubmitStatus("success");
          setFormData({ name: "", email: "", message: "" });
          setTimeout(() => {
            setSubmitStatus(null);
            if (onSubmitSuccess) onSubmitSuccess();
          }, 3000);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          setSubmitStatus("error");
        });
    }
  };

  return (
    <>
      {submitStatus === "success" && (
        <Alert variant="success">
          Thank you for your message. We'll get back to you soon!
        </Alert>
      )}
      {submitStatus === "error" && (
        <Alert variant="danger">
          An error occurred while sending your message. Please try again later.
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            isInvalid={!!errors.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="w-100"
          disabled={submitStatus === "sending"}
        >
          {submitStatus === "sending" ? "Sending..." : "Submit"}
        </Button>
      </Form>
    </>
  );
};

export default ContactForm;
