import React from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../imgs/logo-footer.png";

function Footer() {
  return (
    <div className="footer-div">
      <Row>
        <Col>
          <img
            src={logo}
            alt="Logo"
            className="img-fluid footer-logo"
            width={50}
            height={50}
          />

          <style>
            {`              @keyframes fadeIn {
                from { opacity: 0; }
                to { opacity: 1; }
              }
            `}
          </style>
        </Col>
      </Row>

      <Row>
        <Col>
          <Nav className="text-center footer-links">
            <Nav.Link as={Link} to="/" className="footer-link">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/#services" className="footer-link">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/#about-us" className="footer-link">
              Pricing
            </Nav.Link>
            <Nav.Link as={Link} to="/#contact-us" className="footer-link">
              Contact Us
            </Nav.Link>
          </Nav>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Nav className="text-center footer-links">
            <Nav.Link as={Link} to="/privacy-policy" className="footer-link">
              Privacy Policy
            </Nav.Link>
            <Nav.Link as={Link} to="/terms-conditions" className="footer-link">
              Terms & Conditions
            </Nav.Link>
          </Nav>
        </Col>
      </Row>

      <Row>
        <Col className="text-center copyright">
          <span>
            Website Developed & Maintained by{" "}
            <a
              href="https://tausifmeah.co.uk/"
              target="_blank"
              rel="noreferrer"
              className="footer-link--t"
            >
              Tausif Meah
            </a>
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
