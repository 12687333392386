import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Nav, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FaUserCircle, FaCrown, FaCreditCard } from "react-icons/fa";

function Dashboard() {
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [error, setError] = useState("");
  const db = getFirestore();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            setUserDetails({
              ...userData,
              email: currentUser.email,
            });

            // If stripeCustomerId doesn't exist, get or create it
            if (!userData.stripeCustomerId) {
              await getOrCreateStripeCustomer(currentUser.email);
            }
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          setError("Failed to load user details");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [currentUser, db]);

  const getOrCreateStripeCustomer = async (email) => {
    try {
      const response = await fetch(
        "https://us-central1-content-gen-777b7.cloudfunctions.net/getOrCreateStripeCustomer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get or create Stripe customer");
      }

      const { stripeCustomerId } = await response.json();
      // Update local state
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        stripeCustomerId,
      }));
    } catch (error) {
      console.error("Error getting or creating Stripe customer:", error);
      setError("Failed to set up Stripe customer. Please try again later.");
    }
  };

  const redirectToCustomerPortal = async () => {
    try {
      const response = await fetch(
        "https://us-central1-content-gen-777b7.cloudfunctions.net/createCustomerPortalSession",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: currentUser.uid }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create customer portal session");
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error("Error creating customer portal session:", error);
      setError("Failed to access customer portal. Please try again later.");
    }
  };

  if (loading || !userDetails) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="custom-vh">
      <Container className="dashboard-container mt-5 pt-5">
        <Row>
          <Col md={3}>
            <Card className="profile-sidebar mb-4">
              <Card.Body>
                <div className="text-center mb-4">
                  <FaUserCircle size={80} className="text-primary mb-3" />
                  <h4>{currentUser.displayName || currentUser.email}</h4>
                </div>
                <Nav className="flex-column profile-nav">
                  <Nav.Link
                    onClick={() => setActiveTab("dashboard")}
                    active={activeTab === "dashboard"}
                  >
                    <FaUserCircle className="me-2" /> Dashboard
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => setActiveTab("subscription")}
                    active={activeTab === "subscription"}
                  >
                    <FaCrown className="me-2" /> Subscription
                  </Nav.Link>
                </Nav>
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Card className="dashboard-content">
              <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}

                {activeTab === "dashboard" && (
                  <>
                    <h3 className="mb-4">Dashboard</h3>
                    {/* Add your dashboard content here */}
                  </>
                )}

                {activeTab === "subscription" && (
                  <>
                    <h3 className="mb-4">Subscription Details</h3>
                    <Button
                      variant="primary"
                      onClick={redirectToCustomerPortal}
                      className="mb-3"
                    >
                      <FaCreditCard className="me-2" /> Manage Subscription
                    </Button>
                    <p>
                      Click the button above to manage your subscription, update
                      payment methods, and view billing history.
                    </p>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Dashboard;
