import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "../utils/analytics";

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Get page name from pathname
    const pageName =
      location.pathname === "/"
        ? "Home"
        : location.pathname
            .split("/")
            .filter(Boolean)
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
            .join(" ");

    // Track page view
    trackPageView(pageName);
  }, [location]);
};
