import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaStar, FaQuoteLeft } from "react-icons/fa";

const TestimonialShowcase = () => {
  const testimonials = [
    {
      id: 1,
      name: "Culture Clash Family",
      content:
        "This platform has been incredible for our family channel. We've generated so many TikTok ideas effortlessly, and it's really boosted our content creation. Highly recommend for anyone looking to grow their TikTok presence!",
      rating: 5,
    },
    {
      id: 2,
      name: "Ibrah",
      content:
        "Creating videos for TikTok shop used to be a real challenge for me. Viralz has made it so much easier, and I've seen my views and engagement increase significantly. It's been a game-changer for my account.",
      rating: 4,
    },
    {
      id: 3,
      name: "Brands Watch",
      content:
        "I was hesitant to start on TikTok due to lack of creative ideas. Now, with this platform, I'm seeing steady growth with content that fits my style perfectly. Can't recommend it enough for fellow creators!",
      rating: 5,
    },
  ];

  const steps = [
    {
      id: 1,
      title: "Sign Up",
      content: "Create your account in just a few clicks.",
    },
    {
      id: 2,
      title: "Generate TikTok Content Ideas",
      content: "Save your ideas to your account.",
    },
    {
      id: 3,
      title: "Start Creating",
      content: "Follow the content strategies and start creating.",
    },
    {
      id: 4,
      title: "Watch Your Growth",
      content: "Sit back and watch your TikTok presence skyrocket!",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <Container className="testimonial-showcase mt-5">
      <Row className="align-items-stretch">
        <Col md={6}>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="text-center mb-4 gradient-text">How It Works</h2>
            {steps.map((step) => (
              <motion.div
                key={step.id}
                variants={itemVariants}
                className="step-container mb-4"
              >
                <div className="step-content-wrapper">
                  <div className="step-number">
                    <span className="gradient-border">{step.id}</span>
                  </div>
                  <div className="step-text">
                    <h3 className="step-title">{step.title}</h3>
                    <p className="step-description">{step.content}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </Col>

        <Col md={6}>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="text-center mb-4 gradient-text">
              What our users think
            </h2>
            {testimonials.map((testimonial) => (
              <motion.div
                key={testimonial.id}
                variants={itemVariants}
                className="testimonial-card-wrapper"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="testimonial-card">
                  <FaQuoteLeft className="quote-icon" />
                  <h4 className="testimonial-name">{testimonial.name}</h4>
                  <p className="testimonial-content">{testimonial.content}</p>
                  <div className="rating-container">
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        className={
                          index < testimonial.rating
                            ? "star-icon active"
                            : "star-icon"
                        }
                      />
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default TestimonialShowcase;
