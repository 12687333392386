import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

function SubscriptionRoute({ children }) {
  const { currentUser } = useAuth();
  const [hasSubscription, setHasSubscription] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSubscription = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setHasSubscription(
            userData.hasSubscription === true ||
              userData.subscriptionStatus === "trialing"
          );
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
      }

      setLoading(false);
    };

    checkSubscription();
  }, [currentUser]);

  if (loading) {
    return null;
  }

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  if (!hasSubscription) {
    return <Navigate to="/pricing" />;
  }

  return children;
}

export default SubscriptionRoute;
