import React from "react";

const ConsentPopup = ({ onAccept, onDecline }) => {
  return (
    <div className="consent-popup">
      <div className="consent-popup-content">
        <h2>Subscribe to Our Newsletter</h2>
        <p>Stay updated with our latest offers and content ideas!</p>
        <div className="consent-popup-buttons">
          <button onClick={onAccept} className="consent-popup-accept">
            Subscribe
          </button>
          <button onClick={onDecline} className="consent-popup-decline">
            No, thanks
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;
