import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function TermsConditions() {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow">
            <Card.Body className="p-5">
              <h1 className="text-center mb-4">Terms and Conditions</h1>
              <p className="text-muted mb-4">
                Last updated: {new Date().toLocaleDateString()}
              </p>

              <section className="mb-4">
                <h2>1. Agreement to Terms</h2>
                <p>
                  By accessing or using Content Generator, you agree to be bound
                  by these Terms and Conditions and our Privacy Policy. If you
                  disagree with any part of the terms, you do not have
                  permission to access the service.
                </p>
              </section>

              <section className="mb-4">
                <h2>2. Use License</h2>
                <p>
                  We grant you a limited, non-exclusive, non-transferable, and
                  revocable license to use our service for your personal or
                  business use, subject to these Terms and Conditions.
                </p>
                <p>You may not:</p>
                <ul>
                  <li>
                    Modify or copy the materials outside of normal service usage
                  </li>
                  <li>
                    Use the materials for any commercial purpose not authorized
                    by us
                  </li>
                  <li>
                    Attempt to decompile or reverse engineer any software
                    contained in Content Generator
                  </li>
                  <li>Remove any copyright or other proprietary notations</li>
                  <li>
                    Transfer the materials to another person or "mirror" the
                    materials on any other server
                  </li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>3. User Accounts</h2>
                <p>
                  When you create an account with us, you must provide accurate,
                  complete, and current information. Failure to do so
                  constitutes a breach of the Terms, which may result in
                  immediate termination of your account.
                </p>
                <p>You are responsible for:</p>
                <ul>
                  <li>
                    Maintaining the confidentiality of your account and password
                  </li>
                  <li>Restricting access to your computer and/or account</li>
                  <li>
                    Accepting responsibility for any and all activities that
                    occur under your account
                  </li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>4. Subscription and Payments</h2>
                <p>
                  Some aspects of the service are provided for a fee. You agree
                  to provide current, complete, and accurate purchase and
                  account information for all purchases made via the site.
                </p>
                <p>
                  You agree to promptly update your account and other
                  information so that we can complete your transactions and
                  contact you as needed.
                </p>
              </section>

              <section className="mb-4">
                <h2>5. Content Generation</h2>
                <p>
                  Our service generates content based on your inputs. You
                  understand and agree that:
                </p>
                <ul>
                  <li>
                    Generated content is for inspiration and guidance only
                  </li>
                  <li>
                    You are responsible for reviewing and verifying all
                    generated content
                  </li>
                  <li>
                    We do not guarantee the accuracy or uniqueness of generated
                    content
                  </li>
                  <li>
                    You maintain responsibility for how you use the generated
                    content
                  </li>
                </ul>
              </section>

              <section className="mb-4">
                <h2>6. Intellectual Property</h2>
                <p>
                  The service and its original content, features, and
                  functionality are owned by Content Generator and are protected
                  by international copyright, trademark, patent, trade secret,
                  and other intellectual property laws.
                </p>
              </section>

              <section className="mb-4">
                <h2>7. Termination</h2>
                <p>
                  We may terminate or suspend your account immediately, without
                  prior notice or liability, for any reason, including without
                  limitation if you breach the Terms.
                </p>
                <p>
                  Upon termination, your right to use the service will
                  immediately cease. If you wish to terminate your account, you
                  may simply discontinue using the service.
                </p>
              </section>

              <section className="mb-4">
                <h2>8. Limitation of Liability</h2>
                <p>
                  In no event shall Content Generator, nor its directors,
                  employees, partners, agents, suppliers, or affiliates, be
                  liable for any indirect, incidental, special, consequential or
                  punitive damages, including without limitation, loss of
                  profits, data, use, goodwill, or other intangible losses.
                </p>
              </section>

              <section className="mb-4">
                <h2>9. Changes</h2>
                <p>
                  We reserve the right to modify or replace these Terms at any
                  time. If a revision is material, we will try to provide at
                  least 30 days' notice prior to any new terms taking effect.
                </p>
              </section>

              <section>
                <h2>10. Contact Us</h2>
                <p>
                  If you have any questions about these Terms, please contact
                  us:
                </p>
                <ul>
                  <li>By email: viralzmanagement@gmail.com</li>
                  <li>By visiting our contact page on our website</li>
                </ul>
              </section>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default TermsConditions;
