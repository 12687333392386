import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import BullseyeIcon from "../imgs/bullseye.svg";
import LightbulbIcon from "../imgs/lightbulb.svg";
import ChartLineIcon from "../imgs/chart-line.svg";
import DataIcon from "../imgs/data.svg";
import TimeIcon from "../imgs/time.svg";
import IntegrationIcon from "../imgs/integration.svg";

const reasons = [
  {
    id: 1,
    icon: BullseyeIcon,
    title: "Niche-Specific Content",
    description:
      "Get video ideas tailored specifically to your niche, ensuring relevance and engagement with your target audience.",
  },
  {
    id: 2,
    icon: LightbulbIcon,
    title: "Creative Script Ideas",
    description:
      "Our AI generates innovative and creative scripts, making your TikTok content stand out and captivate viewers.",
  },
  {
    id: 3,
    icon: ChartLineIcon,
    title: "Optimized for Growth",
    description:
      "Ideas are optimized to boost your follower count and increase engagement, helping you grow faster on TikTok.",
  },
  {
    id: 4,
    icon: DataIcon, // Use appropriate SVG for the fourth reason
    title: "Data-Driven Insights",
    description:
      "Our platform provides insights based on real-time data to keep your content strategy ahead of trends.",
  },
  {
    id: 5,
    icon: TimeIcon, // Use appropriate SVG for the fifth reason
    title: "Time-Saving Automation",
    description:
      "Automate the content creation process to save time and focus on what matters most—your creativity.",
  },
  {
    id: 6,
    icon: IntegrationIcon, // Use appropriate SVG for the sixth reason
    title: "Seamless Integration",
    description:
      "Easily integrate with your existing tools and platforms, making it simple to streamline your workflow.",
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 60,
    rotateX: -15,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    y: 0,
    rotateX: 0,
    scale: 1,
    transition: {
      type: "spring",
      duration: 0.8,
      bounce: 0.35,
    },
  },
};

const titleVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const neonColors = {
  primary: "#00f7ff",
  secondary: "#7000ff",
  accent: "#ff00e5",
  dark: "#0a0a0f",
  darker: "#050507",
  glass: "rgba(20, 20, 30, 0.7)",
};

function Process() {
  return (
    <Container
      className="process-container py-5"
      style={{ background: neonColors.darker }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={titleVariants}
      >
        <Row className="section-header mb-5">
          <Col>
            <h1 className="text-center display-4 fw-bold neon-text">
              Why Join Viralz?
            </h1>
            <motion.div
              className="text-center mt-3"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <p className="lead text-light opacity-75">
                Transform your TikTok presence with our powerful features
              </p>
            </motion.div>
          </Col>
        </Row>
      </motion.div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <Row className="justify-content-center g-4">
          {reasons.map((reason, index) => (
            <Col md={4} key={reason.id}>
              <motion.div
                className="neon-card p-4"
                variants={itemVariants}
                whileHover={{
                  y: -15,
                  scale: 1.02,
                  boxShadow: `0 0 20px ${neonColors.primary}40`,
                }}
                transition={{ duration: 0.2 }}
              >
                <motion.div className="icon-wrapper mb-4">
                  <div className="neon-icon-bg">
                    <img
                      src={reason.icon}
                      alt={reason.title}
                      className="reason-icon"
                    />
                  </div>
                </motion.div>
                <h3 className="reason-title h4 mb-3 fw-bold neon-text-accent">
                  {reason.title}
                </h3>
                <p className="reason-description text-light opacity-75">
                  {reason.description}
                </p>
              </motion.div>
            </Col>
          ))}
        </Row>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.8 }}
      >
        <Row className="justify-content-center pt-5 ">
          <Col md={6} className="text-center">
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button
                variant="primary"
                size="lg"
                className="neon-button"
                onClick={() =>
                  document
                    .querySelector(".min-vh-100")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                Get Started Now
                <motion.span
                  className="ms-2"
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1 }}
                >
                  →
                </motion.span>
              </Button>
            </motion.div>
          </Col>
        </Row>
      </motion.div>
    </Container>
  );
}

export default Process;
