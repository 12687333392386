import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

// Configure auth persistence
setPersistence(auth, browserLocalPersistence);

// Initialize Google provider
const googleProvider = new GoogleAuthProvider();
// Always show account selection prompt for better reliability
googleProvider.setCustomParameters({
  prompt: "select_account",
});

// Authentication functions
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result;
  } catch (error) {
    console.error("Error signing in with Google:", error);
    // If first attempt fails due to no session, try again with account selection
    if (
      error.code === "auth/account-exists-with-different-credential" ||
      error.code === "auth/no-auth-session"
    ) {
      googleProvider.setCustomParameters({ prompt: "select_account" });
      return await signInWithPopup(auth, googleProvider);
    }

    // Handle other specific auth errors
    if (error.code === "auth/popup-closed-by-user") {
      throw new Error("Sign-in was cancelled");
    } else if (error.code === "auth/popup-blocked") {
      throw new Error(
        "Pop-up was blocked by the browser. Please allow pop-ups for this site."
      );
    } else if (error.code === "auth/unauthorized-domain") {
      throw new Error(
        "This domain is not authorized for Google Sign-In. Please contact support."
      );
    } else {
      throw error;
    }
  }
};

const logOut = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error signing out:", error);
    throw error;
  }
};

export { auth, db, analytics, signInWithGoogle, logOut };
