export const isInWebview = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    userAgent.includes("tiktok") ||
    userAgent.includes("instagram") ||
    userAgent.includes("fbav") ||
    userAgent.includes("fb_iab")
  );
};

export const handleTikTokWebview = (setError) => {
  if (isInWebview()) {
    return false;
  }
  return false;
};
