import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  Badge,
  Modal,
  Form,
  InputGroup,
  Alert,
  Spinner,
} from "react-bootstrap";
import {
  FaSearch,
  FaCalendarAlt,
  FaPersonBooth,
  FaStore,
  FaTags,
  FaTrash,
  FaArchive,
  FaLightbulb,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

function IdeaVault() {
  const { currentUser } = useAuth();
  const [savedIdeas, setSavedIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [ideaToDelete, setIdeaToDelete] = useState(null);
  const [error, setError] = useState("");
  const db = getFirestore();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          const ideas = userData.ideas || [];
          // Ensure each idea has a status, default to 'draft'
          const ideasWithStatus = ideas.map((idea) => ({
            ...idea,
            status: idea.status || "draft",
          }));
          // Sort ideas by date in descending order (newest first)
          const sortedIdeas = ideasWithStatus.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setSavedIdeas(sortedIdeas);
        } else {
          setSavedIdeas([]);
        }
      } catch (error) {
        setError("Failed to load ideas. Please try refreshing the page.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [currentUser, db]);

  const filteredIdeas = savedIdeas.filter(
    (idea) =>
      idea.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      idea.tags.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      idea.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteIdea = async (ideaToDelete) => {
    try {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        ideas: arrayRemove(ideaToDelete),
      });
      setSavedIdeas((prevIdeas) =>
        prevIdeas.filter((idea) => idea.id !== ideaToDelete.id)
      );
    } catch (error) {
      setError("Failed to delete idea");
    }
  };

  const handleDeleteClick = (idea) => {
    setIdeaToDelete(idea);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (ideaToDelete) {
      await deleteIdea(ideaToDelete);
      setShowDeleteModal(false);
      setIdeaToDelete(null);
    }
  };

  const renderIdeaTitle = (idea) => {
    // First check if there's a direct title property
    if (idea?.title) return idea.title;

    if (!idea?.content) return "Untitled Idea";

    const parser = new DOMParser();
    const doc = parser.parseFromString(idea.content, "text/html");

    // Look for the title in the Video Basics section
    const videoBasicsTitle = doc.querySelector("h3");
    if (videoBasicsTitle?.textContent.trim() === "Video Basics") {
      const titleParagraph = videoBasicsTitle.nextElementSibling;
      if (titleParagraph) {
        const titleText = titleParagraph.textContent.trim();
        const title = titleText
          .replace("Catchy Video Title:", "")
          .replace("Video Title:", "")
          .replace(/^["']|["']$/g, "") // Remove quotes
          .trim();
        if (title) return title;
      }
    }

    return "Untitled Idea";
  };

  const renderVideoBasics = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    const videoBasicsSection = Array.from(doc.querySelectorAll("h2")).find(
      (el) => el.textContent.trim() === "Video Basics"
    );

    if (videoBasicsSection) {
      let titleElement = videoBasicsSection.nextElementSibling;
      let captionElement = titleElement?.nextElementSibling;

      const titleContent = titleElement?.textContent.trim() || "";
      const title =
        titleContent
          .replace("Catchy Video Title:", "")
          .replace("Video Title:", "")
          .trim() || "No title available";

      const captionContent = captionElement?.textContent.trim() || "";
      const caption =
        captionContent.replace("Engaging Caption:", "").trim() ||
        "No caption available";

      return (
        <div>
          <h3>{title}</h3>
          <p>{caption}</p>
        </div>
      );
    }
    return null;
  };

  const renderOtherSections = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const sections = Array.from(doc.querySelectorAll("h2")).filter(
      (el) => el.textContent.trim() !== "Video Basics"
    );

    return (
      <>
        {sections.map((section, index) => {
          let sectionContent = section.outerHTML;
          let nextElement = section.nextElementSibling;
          while (nextElement && nextElement.tagName !== "H2") {
            sectionContent += nextElement.outerHTML;
            nextElement = nextElement.nextElementSibling;
          }
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(sectionContent),
              }}
            />
          );
        })}
      </>
    );
  };

  const toggleStatus = async (idea) => {
    try {
      const newStatus = idea.status === "published" ? "draft" : "published";
      const updatedIdea = { ...idea, status: newStatus };

      // Update in Firestore
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        ideas: arrayRemove(idea),
      });
      await updateDoc(userRef, {
        ideas: [...savedIdeas.filter((i) => i.id !== idea.id), updatedIdea],
      });

      // Update local state
      setSavedIdeas((prevIdeas) =>
        prevIdeas.map((i) => (i.id === idea.id ? updatedIdea : i))
      );
    } catch (error) {
      setError("Failed to update idea status");
    }
  };

  if (loading) {
    return (
      <Container className="idea-vault-container my-5 rounded-2 py-5">
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="mt-3">Loading your ideas...</p>
        </div>
      </Container>
    );
  }

  return (
    <Container className="idea-vault-container my-5 rounded-2 py-5">
      <h2 className="idea-vault-title">
        <FaArchive className="me-2" /> Idea Vault
      </h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="px-4 d-flex flex-md-row flex-column-reverse">
        <Col md={8}>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
            <Form.Control
              className="search-bar"
              placeholder="Search ideas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
          {filteredIdeas.length > 0 ? (
            <ListGroup variant="flush">
              {filteredIdeas.map((idea, index) => {
                return (
                  <ListGroup.Item
                    key={idea.id || index}
                    className="idea-preview"
                  >
                    <div className="d-flex align-items-end justify-content-between">
                      <div
                        className="flex-grow-1 cursor-pointer"
                        onClick={() => {
                          setSelectedIdea(idea);
                          setShowModal(true);
                        }}
                      >
                        <h5 className="idea-title">{renderIdeaTitle(idea)}</h5>
                        <div className="idea-tags">
                          {idea.tags.map((tag, index) => (
                            <Badge bg="secondary" key={index} className="me-1">
                              {tag}
                            </Badge>
                          ))}
                          <div
                            className="status-text"
                            style={{
                              display: "inline-block",
                              color:
                                idea.status === "published"
                                  ? "#28a745"
                                  : "#dc3545",
                              fontWeight: "500",
                              marginLeft: "8px",
                            }}
                          >
                            {idea.status === "published"
                              ? "Published"
                              : "Draft"}
                          </div>
                        </div>
                        <div className="idea-meta">
                          <small className="">
                            <FaCalendarAlt className="me-1" />
                            {new Date(idea.date).toLocaleDateString()} -
                            {idea.generationType === "personal" ? (
                              <>
                                <FaPersonBooth className="ms-1 me-1" /> Personal
                                Brand
                              </>
                            ) : (
                              <>
                                <FaStore className="ms-1 me-1" /> Business
                              </>
                            )}
                          </small>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          className="me-2"
                          onClick={() => toggleStatus(idea)}
                          title={
                            idea.status === "published"
                              ? "Mark as Draft"
                              : "Publish"
                          }
                        >
                          {idea.status === "published" ? (
                            <FaEyeSlash />
                          ) : (
                            <FaEye />
                          )}
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleDeleteClick(idea)}
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          ) : (
            <p>
              No ideas found. Try adjusting your search or generate new ideas!
            </p>
          )}
        </Col>
        <Col md={4}>
          <Card className="stats-card">
            <Card.Body>
              <h4 className="mb-3">Idea Stats</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Total Ideas:</strong> {savedIdeas.length}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Personal Brand Ideas:</strong>{" "}
                  {
                    savedIdeas.filter(
                      (idea) =>
                        idea.generationType?.toLowerCase() === "personal"
                    ).length
                  }
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>TikTok Shop Ideas:</strong>{" "}
                  {
                    savedIdeas.filter(
                      (idea) => idea.generationType?.toLowerCase() === "shop"
                    ).length
                  }
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <Card className="generate-card mb-4">
            <Card.Body>
              <h4 className="mb-3">Generate New Ideas</h4>
              <p>Create content for your personal brand or TikTok shop.</p>
              <Link to="/generate">
                <Button className="generate-btn w-100">
                  <FaLightbulb className="me-2" /> Generate Ideas
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        className="idea-modal"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          {selectedIdea && (
            <>
              <div className="idea-content">
                {renderVideoBasics(selectedIdea.content)}
                {renderOtherSections(selectedIdea.content)}
              </div>
              <div className="mt-4">
                <FaTags className="me-2" />
                {selectedIdea.tags.map((tag, index) => (
                  <Badge bg="primary" key={index} className="me-1">
                    {tag}
                  </Badge>
                ))}
              </div>
              <div className="mt-3 text-muted">
                <FaCalendarAlt className="me-2" />
                Created on: {new Date(selectedIdea.date).toLocaleDateString()}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this idea? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default IdeaVault;
