import React, { useState } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Generate from "./components/Generator";
import Profile from "./components/Profile";
import Affiliate from "./components/Affiliate";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import SubscriptionRoute from "./components/SubscriptionRoute";
import "./styles/style.css";
import Pricing from "./components/Pricing";
import IdeaVault from "./components/IdeaVault";
import { useAnalytics } from "./hooks/useAnalytics";

// Load your Stripe publishable key
const stripePromise = loadStripe("your_publishable_key_here");

// Simple full-screen spinner component
export const FullScreenSpinner = () => (
  <div className="full-screen-spinner">
    <div className="spinner"></div>
  </div>
);

export const SpinnerContext = React.createContext();

function StarryBackground({ children }) {
  const location = useLocation();
  const isHomeOrRoot =
    location.pathname === "/" || location.pathname === "/home";

  return (
    <div className={`starry-container ${isHomeOrRoot ? "home-starry" : ""}`}>
      <div className="stars">
        {[...Array(100)].map((_, i) => (
          <div
            key={i}
            className="star"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          ></div>
        ))}
      </div>
      {children}
    </div>
  );
}

function AppContent() {
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <SpinnerContext.Provider value={{ showSpinner, setShowSpinner }}>
      {showSpinner && <FullScreenSpinner />}
      <div className="app-container">
        <StarryBackground>
          <Navigation />
        </StarryBackground>
        <div className="main-content">
          <AppRoutes />
        </div>
        <Footer />
      </div>
    </SpinnerContext.Provider>
  );
}

function AppRoutes() {
  // Initialize analytics tracking
  useAnalytics();

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />

      {/* Protected routes that require subscription */}
      <Route
        path="/generate"
        element={
          <PrivateRoute>
            <SubscriptionRoute>
              <Generate />
            </SubscriptionRoute>
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <SubscriptionRoute>
              <Dashboard />
            </SubscriptionRoute>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <SubscriptionRoute>
              <Profile />
            </SubscriptionRoute>
          </PrivateRoute>
        }
      />
      <Route
        path="/affiliate"
        element={
          <PrivateRoute>
            <SubscriptionRoute>
              <Affiliate />
            </SubscriptionRoute>
          </PrivateRoute>
        }
      />
      <Route
        path="/idea-vault"
        element={
          <PrivateRoute>
            <SubscriptionRoute>
              <IdeaVault />
            </SubscriptionRoute>
          </PrivateRoute>
        }
      />

      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <AppContent />
        </Elements>
      </AuthProvider>
    </Router>
  );
}

export default App;
