import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { trackEvent } from "../utils/analytics";
import Auth from "./Auth";

function Login() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      console.log("User already signed in:", currentUser.email);
      trackEvent("auth_redirect", {
        from: "login",
        to: "generate-ideas",
        reason: "already_signed_in",
      });
      navigate("/generate-ideas");
    }
  }, [currentUser, navigate]);

  return (
    <div className="starry-background">
      <Auth />
    </div>
  );
}

export default Login;
